<template></template>

<script>
export default {
  components: {},
  data() {
    return {}
  },
  created() {
    const lastProject = localStorage.getItem('last_project')

    if (lastProject) {
      const project = this.Me.projects.find(p => p.name == lastProject)

      if (project) {
        this.$router.push({ name: 'Dashboard', params: { project: project.name } })
        return
      }
    }

    this.$router.push({ name: 'Projects' })
  },
  computed: {},
  methods: {},
}
</script>
